import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Card as BSCard } from 'react-bootstrap';

// TODO: change component name, it's conflicting with Bootstrap
const Card = ({ title, children, to, md = 4, warning }) => {
  const card = (
    <BSCard className={`${warning ? ' card--warning' : ''}`}>
      <BSCard.Title className={`card__title${warning ? ' card__title--warning' : ''}`}>{title}</BSCard.Title>
      <BSCard.Body className={`card__content`}>{children}</BSCard.Body>
    </BSCard>
  );
  return (
    <Col md={md}>
      {to ? (
        <Link to={to} className="card__link">
          {card}
        </Link>
      ) : (
        card
      )}
    </Col>
  );
};

export default Card;
