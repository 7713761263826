import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, Container } from 'react-bootstrap';
import RouterNavItem from './RouterNavItem';
import { ReactComponent as Logo } from '../images/logo.svg';

const Header = () => (
  <Navbar bg="light" expand="md" collapseOnSelect className="header">
    <Container>
      <Navbar.Brand>
        <Link to="/">
          <Logo height="45px" />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" />
        <Nav>
          <RouterNavItem to="/" exact>
            Início
          </RouterNavItem>
          <RouterNavItem to="/empresa">Empresa</RouterNavItem>
          <RouterNavItem to="/equipe">Equipe</RouterNavItem>
          <RouterNavItem to="/servicos">Serviços</RouterNavItem>
          <RouterNavItem to="/contato">Contato</RouterNavItem>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default Header;
