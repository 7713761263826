import { Container } from 'react-bootstrap';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import Company from './company';
import Contacts from './contacts';
import Footer from './footer';
import Header from './header';
import Home from './home';
import Services from './services';
import Team from './team';

import '../style/App.scss';

function Frame() {
  return (
    <>
      <Header />
      <Container className="content">
        <Outlet />
      </Container>
      <Footer />
    </>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Frame />}>
          <Route path="/" element={<Home />} />
          <Route path="contato" element={<Contacts />} />
          <Route path="empresa" element={<Company />} />
          <Route path="equipe" element={<Team />} />
          <Route path="servicos" element={<Services />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
