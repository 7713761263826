import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Frame from './frame';

const Photo = ({ gender }) => {
  let className;
  if (gender === 'female') {
    className = 'photo_woman';
  } else {
    className = 'photo_man';
  }
  return (
    <Frame className="team__frame">
      <div className={`photo ${className}`}></div>
    </Frame>
  );
};

const Member = ({ name, gender, children }) => (
  <Col sm={6} className="team__member">
    <Photo gender={gender} />
    <h3>{name}</h3>
    {children}
  </Col>
);

const Team = () => (
  <Row>
    <Member name="Dr. Milton M. Castro">
      CRM-PE: 5.800
      <br />
      Alergista e imunologista pela Sociedade Brasileira de Alergia e Imunopatologia - ASBAI/AMB
      <br />
      Pós-graduado em Alergia pela PUC-RJ
      <br />
      Pós-graduado em Imunologia pela UFRJ
    </Member>
    <Member gender="female" name="Dra. Roberta A. Castro">
      CRM-PE: 17.809 | CRM-SP: 153.449
      <br />
      Alergista e imunologista pela Sociedade Brasileira de Alergia e Imunopatologia - ASBAI/AMB
      <br />
      Médica colaboradora do Ambulatório de Alergia Alimentar do HC-FMUSP.
      <br />
      Especialização no Serviço de Alergia e Imunologia Clinica do HC-FMUSP.
      <br />
    </Member>
  </Row>
);

export default Team;
