import React from 'react';
import Frame from './frame';
import { Col, Row } from 'react-bootstrap';

const Company = () => (
  <Row>
    <Col sm={6} md={5} lg={4} className="d-none d-sm-block company">
      <Frame>
        <div className="company__side_bar"></div>
      </Frame>
    </Col>
    <Col sm={6} md={7} lg={8} className="company">
      <p>
        Allergo Centro é uma clínica especializada no diagnóstico e tratamento de doenças Alérgicas e Imunológicas como:
      </p>
      <ul>
        <li>Alergias respiratórias (Asma e rinite)</li>
        <li>Alergias alimentares</li>
        <li>Alergias a medicamentos</li>
        <li>Alergias dermatológicas (urticária/ angioedema, dermatite atópica, dermatite de contato)</li>
        <li>Conjuntivite alérgica</li>
        <li>Anafilaxia</li>
        <li>Imunodeficiências</li>
      </ul>
      <p>
        Onde além da consulta médica, realizada por profissionais altamente especializados, também é oferecida toda
        estrutura para realização de testes complementares para diagnóstico preciso de doenças alérgicas.
      </p>
      <h3>Missão</h3>
      <p>
        Prestar atendimento médico de ponta, sempre atualizado e baseado em evidências, oferecendo procedimentos padrão
        ouro para maior acurácia no diagnóstico e tratamento das doenças alérgicas. Para isso, contamos com médicos
        altamente especializados e capacitados comprometidos em promover saúde e qualidade de vida aos nossos pacientes.
      </p>
      <h3>Visão</h3>
      <p>
        Oferecer aos portadores de doenças alérgicas e/ou imunológicas um atendimento individualizado, especializado,
        humanizado e completo .
      </p>
      <h3>Valores</h3>
      <p>
        O Allergo Centro valoriza a prática médica com ética, competência, seriedade e respeito ao próximo, buscando
        sempre o aperfeiçoamento pessoal e profissional.
      </p>
    </Col>
  </Row>
);

export default Company;
