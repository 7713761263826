import { ReactNode } from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { To } from 'react-router-dom';

interface RouterNavItemProps {
  children: ReactNode;
  to: To;
}

export default function RouterNavItem({ to, children }: RouterNavItemProps) {
  return (
    <LinkContainer to={to}>
      <Nav.Link> {children}</Nav.Link>
    </LinkContainer>
  );
}
