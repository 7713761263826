import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as Instagram } from '../images/instagram.svg';
import { ReactComponent as Twitter } from '../images/twitter.svg';

const Contacts = () => (
  <Row>
    <Col sm={6} md={4} className="contacts">
      Rua Cardeal Arcoverde, 308
      <br />
      Graças | Recife-PE CEP: 52011-240
      <br />
      E-mail: contato@allergocentro.com.br
      <br />
      Tel.: (81) 3032-6620 | 98183-1601
      <div>
        <a href="https://twitter.com/AllergoCentro" target="_blank" rel="noopener noreferrer">
          <Twitter height="32px" title="Twitter" />
        </a>{' '}
        <a href="https://instagram.com/allergocentro/" target="_blank" rel="noopener noreferrer">
          <Instagram height="32px" title="Instagram" />
        </a>
      </div>
    </Col>
    <Col sm={6} md={8} className="contacts">
      <div className="contacts_map">
        <iframe
          title="mapa"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.3274846764025!2d-34.89494298459278!3d-8.068041082864502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab18ce516b25ad%3A0xf2fbeb4c0bcadb1b!2sAlergocentro!5e0!3m2!1spt-BR!2sbr!4v1473635462291"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        />
      </div>
    </Col>
  </Row>
);

export default Contacts;
