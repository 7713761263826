import React from 'react';

const Footer = () => (
  <footer className="footer text-center">
    © {new Date().getFullYear()} por{' '}
    <a href="http://www.inovant.com.br/" target="_blank" rel="noopener noreferrer" title="Inovant Creations">
      Inovant Creations
    </a>{' '}
    para Allergo Centro
  </footer>
);

export default Footer;
