import React from 'react';
import { Row } from 'react-bootstrap';
import Card from './card';
import Jumbotron from './jumbotron';

const Home = () => (
  <>
    <Jumbotron />
    <Row>
      <Card md={12} title="Novo Endereço" warning>
        <p>Prezados clientes,</p>
        <p>
          Nos mudamos para o, recém-inaugurado, <a href="http://espacomaetamorfose.com.br/">Espaço Mãetamorfose
          </a> da Zona Norte. A área conta com estacionamento e ampla sala de espera com espaço kids, tudo isso para oferecer mais conforto e praticidade a vocês.
        </p>
        <p>
          Nossa nova casa fica na:<br/>
          <b>Rua Cardeal Arcoverde, 308<br /> Graças, Recife-PE<br />
          CEP: 52011-240</b>
        </p>
        <p>
          Marcações de consulta devem ser feitas pelo telefone do Espaço Mãetamorfose <b>(81) 3032-6620
          </b> ou mensagem no WhatsApp <b>(81) 98183-1601</b>.
        </p>
        <p>
          Esperamos que gostem!
        </p>
      </Card>
    </Row>
    <Row>
      <Card title="Quem Somos" to="/empresa">
        Somos uma clinica especializada no diagnóstico e tratamento de todas doenças alergicas. Todos os nossos
        profissionais são especializados e titulados pela Associação Brasileira de Alergia e Imunopatologia - ASBAI.
      </Card>
      <Card title="Nossos Serviços" to="/servicos">
        Realizamos testes de leitura imediata (Prick test), Teste de Contato (Patch test) com extratos padronizados de
        alto padrão de qualidade. Além de teste de desencadeamento oral, padrão ouro no diagnóstico das doenças
        alérgicas.
      </Card>
      <Card title="Contato" to="/contato">
        Rua Cardeal Arcoverde, 308
        <br />
        Graças | Recife-PE CEP: 52011-240
        <br />
        E-mail: contato@allergocentro.com.br
        <br />
        Telefone: (81) 3032-6620 | 98183-1601
      </Card>
    </Row>
  </>
);

export default Home;
