import React from 'react';
import { Jumbotron as BSJumbotron } from 'react-bootstrap';

// TODO: change component name, it's conflicting with Bootstrap
const Jumbotron = () => (
  <BSJumbotron className="d-none d-md-block">
    <svg className="jumbotron__text" viewBox="0 0 290 19">
      <text x="0" y="15">
        "O mundo sem alergias é bem mais feliz"
      </text>
    </svg>
  </BSJumbotron>
);

export default Jumbotron;
