import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Services = () => (
  <Row>
    <Col xs={12} className="services">
      O Allergo Centro está preparado para a realização de tratamentos de risco como: imunoterapia para venenos de
      insetos, imunoterapia para alergias respiratórias, para desencadeamento oral com medicamentos e alimentos.
    </Col>
    <Col sm={12} md={4} className="services">
      <h3>Testes Alérgicos</h3>
      <p>
        Os testes alérgicos são utilizados para o diagnóstico em alergia, dentre os mais comuns: teste cutâneo de
        leitura imediata (Prick teste), e o teste de contato (Patch teste). Os testes de alergia são seguros, rápidos e
        permitem confirmar a suspeita clínica da alergia por um determinado agente.
      </p>
      <p>
        O teste cutâneo de leitura imediata é realizado no antebraço, onde se coloca uma gota do extrato da substância a
        ser investigada (alérgeno) e a seguir é feito a puntura da pele, faz-se a leitura do teste em torno de 15
        minutos.
      </p>
      <p>
        O teste de contato é realizado para o diagnóstico da dermatite de contato alérgica, e eventualmente para alergia
        alimentar. A técnica consiste em colar fitas adesivas, geralmente, nas costas do paciente, com substâncias
        padronizadas, as quais ficarão em contato com a pele por 48h. Após este período as fitas serão retiradas e
        realizada a 1º leitura, após 96h do início do teste uma segunda leitura deverá ser realizada para conclusão do
        teste.
      </p>
      <p>
        A interpretação dos testes deve ser sempre correlacionada à história clínica do paciente e realizada por um
        médico especialista capacitado. No Allergo Centro você pode realizar os seus teste com toda segurança e
        conforto.
      </p>
    </Col>
    <Col sm={12} md={4} className="services">
      <h3>Imunoterapia</h3>
      <p>
        A imunoterapia com alérgenos, também chamada de vacina para alergia, é uma forma de tratamento utilizada há mais
        de 100 anos com o objetivo de diminuir a sensibilidade de pessoas que se tornaram alérgicas a determinadas
        substâncias.
      </p>
      <p>
        É o único tratamento capaz de modificar a história natural da doença proporcionando o controle da doença. O
        tratamento consiste na aplicação de alérgeno ao qual o paciente é sensível em doses crescentes por um período de
        tempo que é variável ( 3 a 5 anos).
      </p>
      <p>A Organização Mundial da Saúde (OMS), endossa o emprego das vacinas com alérgenos:</p>
      <ol>
        <li>
          Em pacientes que apresentam reações graves (anafiláticas) a insetos (abelhas, vespas/marimbondos e formigas);
        </li>
        <li>
          Nos indivíduos sensíveis a alérgenos ambientais que apresentem como manifestações clínicas: rinite, asma e/ou
          conjuntivite.
        </li>
      </ol>
    </Col>
    <Col sm={12} md={4} className="services">
      <h3>Desencadeamento oral</h3>
      <p>
        O teste de desencadeamento oral é considerado padrão ouro para diagnóstico de alergia alimentar, e para
        diagnóstico de desenvolvimento de tolerância ao alimento na evolução do paciente com alergia alimentar. Pode ser
        necessário também para diagnóstico de alergia a medicamentos.
      </p>
      <p>
        O teste de desencadeamento oral consiste na administração supervisionada do alimento e/ou medicamento suspeito
        de causar alergia, assim é necessário que seja realizado em ambiente equipado para monitorar o paciente e seja
        supervisionado por médico experiente e treinado para tratamento de uma eventual reação alérgica.
      </p>
    </Col>
  </Row>
);

export default Services;
